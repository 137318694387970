import axios from "axios";
import { STATIC_USER_HOST } from "./config";

export const login = async (values) => {
  try {
    let response = await axios.post(`${STATIC_USER_HOST}user/login`, {
      email: values.email,
      password: values.password,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const register = async (values) => {
  try {
    let response = await axios.post(`${STATIC_USER_HOST}user/register`, {
      email: values.email,
      password: values.password,
      fullName: values.fullName,
    });
    if (response.status === 201) {
      const user = {
        email: values.email,
        password: values.password,
      };
      return user;
    }
  } catch (error) {
    console.log(error);
    return error.response.data.errors[0].msg;
  }
};

export const getCurrentUser = async (setUser) => {
  await axios
    .get(`${STATIC_USER_HOST}user/current`, {
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((response) => {
      setUser(response.data);
    })
    .catch((err) => {
      console.error(err);
    });
};
