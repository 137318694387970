import axios from "axios";
import { STATIC_HOST } from "./config";

const axiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
  },
  params: {
    excludeRoot: false,
  },
};

if (localStorage.getItem("token")) {
  axiosConfig.headers.Authorization = "Bearer " + localStorage.getItem("token");
}

export const createFolder = async (projectCode, newFolderName, node) => {
  try {
    const res = await axios.post(
      `${STATIC_HOST}project/${projectCode}/dir`,
      {
        name: newFolderName,
        path:
          node.path === "/"
            ? `/${newFolderName}`
            : node.path + `/${newFolderName}`,
      },
      axiosConfig
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Creating folder failed", error);
  }
};

export const renameFolder = async (projectCode, newFolderName, node) => {
  try {
    const res = await axios.put(
      `${STATIC_HOST}project/${projectCode}/rename`,
      {
        newPath:
          node.path.substr(0, node.path.lastIndexOf("/")) + "/" + newFolderName,
      },
      { ...axiosConfig, params: { ...axiosConfig.params, path: node.path } }
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Creating folder failed", error);
  }
};

export const getChildren = async (foldepath, projectCode) => {
  if (!projectCode) return [];
  try {
    let res = await axios.get(`${STATIC_HOST}project/${projectCode}/dir`, {
      ...axiosConfig,
      params: { ...axiosConfig.params, path: foldepath },
    });
    return res.data.children;
  } catch (err) {
    console.log("Error getting directory children", err);
    return [];
  }
};
