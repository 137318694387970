import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import JSZipUtils from "jszip-utils";
import { getFileContent } from "../../api/fileAPI";
import useProjectCode from "../../utils/useProjectCode";
import { TiTabsOutline } from "react-icons/ti";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { AiOutlineFileAdd } from "react-icons/ai";
import { AiOutlineFolderAdd } from "react-icons/ai";
import { PiDownloadBold } from "react-icons/pi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { GrDeploy } from "react-icons/gr";
import ContentCopy from "@mui/icons-material/ContentCopy";
import LoadingDialog from "../Modal/LoadingDialog";
import { useDispatch } from "react-redux";
import { setModeState } from "../../store/modeStateSlice";
import { STATIC_HOST } from "../../api/config";
import { getChildren } from "../../api/folderAPI";
import { MEDIA_FILE } from "../../utils/common";

export default function ContextMenu({
  anchorEl,
  handleClose,
  node,
  openFileActionModal,
  openFolderModal,
  children,
  openConfirmModal,
  openDeployModal,
  openVersionModal,
  isDeploySuccess,
}) {
  const open = Boolean(anchorEl);
  const projectCode = useProjectCode();
  const dispatch = useDispatch();

  const [deployPackageId, setDeployPackageId] = React.useState("");

  React.useEffect(() => {
    const getPackageId = async () => {
      try {
        let res = await axios.get(`${STATIC_HOST}project/${projectCode}`);
        setDeployPackageId(res.data.deployPackageId);
      } catch (error) {
        console.log("error", error);
      }
    };
    getPackageId();
  }, [projectCode, isDeploySuccess]);

  const copyURL = () => {
    if (!navigator || !navigator.clipboard) return;
    navigator.clipboard.writeText(node.fileLink);
    toast.success("URL Copied!");
    handleClose();
  };
  const handleRename = () => {
    handleClose();
    if (node.isDir) {
      openFolderModal("rename", node);
    } else {
      openFileActionModal("rename", node);
      dispatch(setModeState("rename"));
    }
  };

  //OpenFileActionModal
  const handleAddNewFile = () => {
    openFileActionModal("newfile", node);
    handleClose();
  };
  //OpenFolderModal
  const handleAddNewFolder = () => {
    openFolderModal("newfolder", node);
    handleClose();
  };
  //OpenConfirmModal
  const onDelete = () => {
    openConfirmModal();
    handleClose();
  };

  const [loading, setLoading] = React.useState(false);
  const handleDownloadZip = async (e) => {
    handleClose();
    setLoading(true);

    function urlToPromise(url) {
      return new Promise(function (resolve, reject) {
        JSZipUtils.getBinaryContent(url, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    }

    var zip = new JSZip();

    const getItems = async (parent, currentFolder) => {
      if (parent.isDir === true) {
        let folder = currentFolder.folder(parent.name); // Create a sub-folder inside the current folder
        const children = await getChildren(parent.path, projectCode);
        children.forEach((child) => {
          getItems(child, folder); // Pass the current sub-folder to maintain the structure
        });
      } else {
        {
          try {
            if (
              MEDIA_FILE.includes(
                parent.name
                  .substring(parent.name.lastIndexOf(".") + 1)
                  .toLowerCase()
              )
            ) {
              const content = parent.fileLink;
              currentFolder.file(parent.name, urlToPromise(content), {
                binary: true,
              });
            } else {
              const res = await getFileContent(projectCode, `${parent.path}`);
              currentFolder.file(parent.name, res.content);
            }
          } catch (error) {
            console.error(`Error fetching file ${projectCode}:`, error);
          }
        }
      }
    };

    for (let i = 0; i < children.length; i++) {
      await getItems(children[i], zip);
    }

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, `${node.name}.zip`);
    });
    setLoading(false);
  };

  const manageContribute = () => {};

  const handleDeploy = () => {
    openDeployModal();
  };

  const handleManageVersion = () => {
    openVersionModal();
  };

  return (
    <div>
      {loading && <LoadingDialog />}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {node?.isDir ? (
          <div>
            {deployPackageId ? (
              <MenuItem onClick={handleManageVersion}>
                <GrDeploy size="0.9rem" />
                Update Version
              </MenuItem>
            ) : (
              <MenuItem onClick={handleDeploy}>
                <GrDeploy size="0.9rem" />
                Deploy to store
              </MenuItem>
            )}

            <MenuItem onClick={manageContribute}>
              <AiOutlineUsergroupAdd size="1rem" />
              Contributor Management
            </MenuItem>
            <MenuItem onClick={handleAddNewFile}>
              <AiOutlineFileAdd size="1rem" />
              Add New File
            </MenuItem>
            <MenuItem onClick={handleAddNewFolder}>
              <AiOutlineFolderAdd size="1rem" />
              Add New Folder
            </MenuItem>
          </div>
        ) : (
          <div>
            <MenuItem onClick={copyURL}>
              <ContentCopy fontSize="1.2rem" />
              Copy URL
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                window.open(`${node.fileLink}`, "_blank");
              }}
            >
              <TiTabsOutline size="1rem" />
              Open preview in new tab
            </MenuItem>
          </div>
        )}
        <MenuItem onClick={handleRename}>
          <MdOutlineDriveFileRenameOutline size="1rem" />
          Rename
        </MenuItem>

        <MenuItem onClick={onDelete}>
          <MdDelete size="1rem" />
          Delete
        </MenuItem>
        {node?.path === "/" && (
          <MenuItem onClick={handleDownloadZip}>
            <PiDownloadBold size="1rem" />
            Download
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
