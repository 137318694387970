import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { STATIC_HOST } from "../api/config";
import ConfirmModal from "../components/Modal/ConfirmModal";
import WholePageDropZone from "../components/DnD/WholePageDropZone";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedPath } from "../store/selectedPathSlice";
import { setSelectedNode } from "../store/selectedNodeSlice";
import { setSelectedType } from "../store/selectedTypeSlice";
import LoadingDialog from "../components/Modal/LoadingDialog";
import { getAllFiles, getProjectChildren } from "../api/TreeAPI/common";
import CustomTreeItem from "../components/CustomTreeItem";
import { getFileTypeFromName } from "../utils/filename";
import useProjectCode from "../utils/useProjectCode";
import { clearActiveNode, setActiveNode } from "../store/activeNodeSlice";

const PREVIEW_FILE_TYPE = ["png","jpg","jpeg","gif","svg","webp","mp3","mp4","m4p","m4v","webm","ogg","mp2","mpg","mpv","mov","mpeg",];
const EDIT_PREVIEW_FILE_TYPE = ["html", "md"];
const IGNORE_FILE_TYPE = ["zip", "rar", "exe", "phong"];

const ProjectSideBar = ({setPreviewFile,setEditFile,setActiveFile,}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectChildren, setProjectChildren] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [projectNode, setProjectNode] = useState(null);
  const [queryFilepath, setQueryFilePath] = useState(null);
  const projectCode = useProjectCode();
  const [triggerReload, setTriggerReload] = useState(false);

  useEffect(() => {
    let filePath = searchParams.get("fileName");

    if (filePath != queryFilepath) {
      setQueryFilePath(filePath);
      handleFilePathChanged(filePath);
    }
  }, [searchParams]);

  useEffect(() => {
    loadProjectInfo();
    dispatch(clearActiveNode());
  }, []);

  const loadProjectInfo = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${STATIC_HOST}project/${id}`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      });
      let project = res.data;
      setProjectNode({
        path: "/",
        name: project.originalname,
        isDir: true,
      });
    } catch (err) {
      console.log("Loading project error: " + err.message);
      setProjectNode(null);
    }
    setLoading(false);
  };

  let { id } = useParams();

  let totalFiles = localStorage.getItem(`${id}_files`);
  let allFiles = [];

  try {
    allFiles = JSON.parse(totalFiles) || [];
  } catch (err) {}

  const handleFilePathChanged = (newPath) => {
    let fileType = getFileTypeFromName(newPath);
    let previewUrl = `${STATIC_HOST}data/projects/${projectCode}${newPath}`;

    if (IGNORE_FILE_TYPE.includes(fileType?.toLowerCase())) {
      return;
    }
    if (PREVIEW_FILE_TYPE.includes(fileType?.toLowerCase())) {
      if (setPreviewFile) setPreviewFile(previewUrl);
      if (setEditFile) setEditFile(newPath);
      return;
    }

    if (EDIT_PREVIEW_FILE_TYPE.includes(fileType?.toLowerCase())) {
      if (setPreviewFile) setPreviewFile(previewUrl);
      if (setEditFile) setEditFile(newPath);
      return;
    }

    if (setEditFile) setEditFile(newPath);
  };

  useEffect(() => {
    let queryFileName = searchParams.get("fileName");
    if (queryFileName && allFiles) {
      let matchFile = allFiles.find((file) => file.name == queryFileName);
      if (matchFile) {
        dispatch(setSelectedPath(matchFile.path));
        dispatch(setSelectedNode(matchFile.path));
        dispatch(
          setSelectedType(
            matchFile.name.substring(matchFile.name.lastIndexOf(".") + 1)
          )
        );
        setActiveFile(matchFile.name);
      }
    }
  }, [allFiles]);

  const [openFileModal, setOpenFileModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getProjectChildren(id, setProjectChildren);
  }, []);

  useEffect(() => {
    getAllFiles(projectChildren, projectCode);
  }, [projectChildren]);

  const onAddSuccess = () => {
    setTriggerReload(!triggerReload);
  };

  return (
    <div className="h-full flex flex-col w-full bg-slate-50 border-r border-gray-300">
      {loading && <LoadingDialog />}
      <div className="text-sm py-2.5 pl-[9px] bg-slate-200 border-b border-gray-300 tracking-wide">
        FILE EXPLORER
      </div>

      <div className="grow flex flex-col">
        <div className="select-none h-[100% - 190px] overflow-y-scroll">
          <CustomTreeItem
            isRoot={true}
            node={projectNode}
            triggerReload={triggerReload}
            openFileModal={openFileModal}
          />
        </div>

        <div className="grow min-h-[100px]">
          <WholePageDropZone onAddSuccess={onAddSuccess} />
        </div>
      </div>

      {/* Confirm Modal */}
      <ConfirmModal setLoading={setLoading} />
    </div>
  );
};

export default ProjectSideBar;
