import { useEffect, useState } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { HiHome } from "react-icons/hi";
import { ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Login from "../Auth/Form/Login";
import Register from "../Auth/Form/Register";
import { RxAvatar } from "react-icons/rx";
import { Logout } from "@mui/icons-material";
import { RiUserLine } from "react-icons/ri";
import { getCurrentUser } from "../api/userAPI";
import { useSelector } from "react-redux";

const PageHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState({});

  function handleClick() {
    location.pathname === "/" ? window.location.reload(false) : navigate("/");
  }

  const requestLoginStatus = useSelector((state) => state.requestLoginStatus);

  useEffect(() => {
    console.log("requestLoginStatus", requestLoginStatus);
    if (requestLoginStatus) {
      onOpenLoginForm();
    }
  }, [requestLoginStatus]);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const onOpenLoginForm = () => {
    setIsOpenLogin(true);
  };
  const onCloseLoginForm = () => {
    setIsOpenLogin(false);
  };

  const [isOpenRegForm, setIsOpenRegForm] = useState(false);
  const onOpenRegForm = () => {
    setIsOpenRegForm(true);
  };

  const onCloseRegForm = () => {
    setIsOpenRegForm(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  useEffect(() => {
    const getUser = async () => {
      if (token) {
        await getCurrentUser(setUser);
      }
    };
    getUser();
  }, [token]);

  return (
    <div className="flex bg-slate-100 py-2 bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-[length:100%_3px] bg-no-repeat bg-bottom">
      <div className="flex w-full mx-8 items-center">
        <Tooltip title="Home" arrow placement="bottom">
          <div
            className={`w-14 transition-transform transform-gpu hover:scale-110 px-1`}
          >
            {location.pathname === "/" ? (
              <HiHome
                size="2.3rem"
                onClick={handleClick}
                className="cursor-pointer m-auto hover:rgb(84, 168, 176)"
                color="#1C6269"
              />
            ) : (
              <HiOutlineHome
                size="2.3rem"
                onClick={handleClick}
                className="cursor-pointer m-auto hover:rgb(84, 168, 176)"
                color="#1C6269"
              />
            )}
          </div>
        </Tooltip>

        <h1
          className="text-3xl h-10 text-center font-bold m-auto cursor-pointer select-none
                    bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-clip-text text-transparent"
          onClick={handleClick}
        >
          digital.auto widget studio
        </h1>

        <div className="">
          <a
            onClick={() => {
              window.open(
                `https://bestudio.digitalauto.tech/project/QDC3xxv1HJlg/index.html`,
                "_blank"
              );
            }}
            className="flex items-center text-[#005072] font-bold text-lg underline cursor-pointer transition-transform transform-gpu hover:scale-110"
          >
            Documentations
          </a>
        </div>

        <div className="h-full bg-[#005072] mx-3 w-0.5"></div>

        {!token && (
          <button
            onClick={onOpenLoginForm}
            className="text-[#1C6269] font-bold text-lg underline transition-transform transform-gpu hover:scale-110"
          >
            Login
          </button>
        )}

        {token && (
          <button className="text-[#1C6269] font-bold text-lg underline transition-transform transform-gpu hover:scale-110">
            <RxAvatar size="2.2rem" onClick={handleClick1} />
          </button>
        )}

        <div>
          <Login
            isOpenLogin={isOpenLogin}
            onCloseLoginForm={onCloseLoginForm}
            onOpenRegForm={onOpenRegForm}
            onLoginSuccess={(token) => {
              onLoginSuccess(token);
            }}
          />
        </div>
        <div>
          <Register
            onCloseRegForm={onCloseRegForm}
            isOpenRegForm={isOpenRegForm}
            onOpenLoginForm={onOpenLoginForm}
          />
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <RiUserLine size="1.2rem" />
          </ListItemIcon>
          {user?.fullName}
        </MenuItem>
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PageHeader;
