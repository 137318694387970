import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { BsFillGridFill } from "react-icons/bs";
import { MdViewList } from "react-icons/md";
import PaginationButtons from "../components/Paginate";
import LoadingDialog from "../components/Modal/LoadingDialog";
import { toast, ToastContainer } from "react-toastify";
import CreateProjectBtn from "../components/Button/CreateProjectBtn";
import SearchAppBar from "../components/SearchBar";
import RenameProject from "../components/Modal/Project/RenameProjectModal";
import GridRender from "../components/ViewTypeComponent/GridRender";
import ListRender from "../components/ViewTypeComponent/ListRender";
import { useDispatch, useSelector } from "react-redux";
import { openModal } from "../store/RenameProjectSlice";
import { setSelectedProject } from "../store/selectedProjectSlice";
import CreateProjectDialog from "../components/Modal/Project/CreateProjectDialog";
import { deleteProject } from "../api/TreeAPI/common";
import { getProjectByUid, getProjectByUserId } from "../api/projectAPI";

MyProjects.propTypes = {};

function MyProjects(props) {
  const params = useParams();
  const [uid, setUid] = useState(
    Object.keys(params).length > 0 ? params.uid : localStorage.getItem("uid")
  );
  const [loading, setLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsName, setAllProjectsName] = useState([]);
  const [viewOption, setViewOption] = useState("grid");
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [renderProject, setRenderProject] = useState([]);

  const [onSearch, setOnSearch] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      listProjectsByUserId();
    } else {
      if (!uid) {
        let tmpUid = uuidv4();
        setUid(tmpUid);
        localStorage.setItem("uid", tmpUid);
      }
      listProjectsByUid();
    }
  }, []);

  const listProjectsByUserId = async () => {
    setLoading(true);
    let res = await getProjectByUserId();
    if (res) {
      setAllProjects(res.data);
      setAllProjectsName(res.data.map((project) => project.originalname));
    } else {
      toast.error("Failed to get user's projects");
      setAllProjects([]);
      setAllProjectsName([]);
    }
    setLoading(false);
  };

  const listProjectsByUid = async () => {
    setLoading(true);
    let res = await getProjectByUid(uid);
    if (res) {
      setAllProjects(res);
      setAllProjectsName(res.map((project) => project.originalname));
    } else {
      toast.error("Couldn't find userId's project");
      setAllProjects([]);
      setAllProjectsName([]);
    }
    setLoading(false);
  };

  const handlePageChange = useCallback((newCurrentItems) => {
    setCurrentItems(newCurrentItems);
  }, []);

  const selectedProject = useSelector((state) => state.selectedProject);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const OpenMenu = (event) => {
    event.stopPropagation();
    const temp = JSON.parse(event.target.getAttribute("file"));
    dispatch(setSelectedProject(temp));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRenameProject = (e) => {
    dispatch(openModal());
  };

  const handleDeleteProject = async () => {
    let res = await deleteProject(selectedProject.name);
    if (res.status === 200) {
      toast.success("Delete project successfully");
      handleClose();
      if (localStorage.getItem("token")) {
        listProjectsByUserId();
      } else {
        listProjectsByUid();
      }
      setAnchorEl(null);
    } else {
      toast.error("Delete project failed");
      handleClose();
    }
  };

  const [openProjectModal, setOpenProjectModal] = useState(false);

  const onOpenCreateProjectModal = () => {
    setOpenProjectModal(true);
  };

  const onCloseCreateProjectModal = () => {
    setOpenProjectModal(false);
  };

  return (
    <div className="w-full select-none z-30">
      <ToastContainer
        autoClose={2500}
        draggablePercent={60}
        style={{ width: "250px", height: "100px", fontSize: "13px" }}
      />

      {loading && <LoadingDialog />}

      <CreateProjectDialog
        setLoading={setLoading}
        allProjectsName={allProjectsName}
        uid={uid}
        openProjectModal={openProjectModal}
        onCloseCreateProjectModal={onCloseCreateProjectModal}
      />

      <div className="flex w-full justify-center px-2 h-[calc(100%-56px)]">
        <div className="w-full max-w-[1280px]">
          <div className="flex w-full justify-between items-center pt-3 pb-4">
            <div>
              <CreateProjectBtn
                onOpenCreateProjectModal={onOpenCreateProjectModal}
              />
            </div>
            <div className="flex z-10">
              <SearchAppBar
                allProjects={allProjects}
                onSearchResult={(result, state) => {
                  setRenderProject(result);
                  setOnSearch(state);
                  setCurrentPage(0);
                }}
              />

              <div className="ml-5 flex w-[85px] md:w-[150px] h-[43px] justify-between items-center border-[#005072] border-2 rounded-3xl cursor-pointer ">
                <div
                  className={`w-[100px] h-[41px] flex justify-center items-center ${
                    viewOption === "grid"
                      ? "rounded-3xl border-[#005072] border text-[#005072] font-bold bg-[#F3F3F3]"
                      : "text-[#00507245]"
                  }`}
                  onClick={() => {
                    setViewOption("grid");
                  }}
                >
                  <BsFillGridFill />
                </div>
                <div
                  className={`w-[100px] h-[41px] flex justify-center items-center  ${
                    viewOption === "list"
                      ? "rounded-3xl border-[#005072] border text-[#005072] font-bold bg-[#F3F3F3]"
                      : "text-[#00507245]"
                  }`}
                  onClick={() => {
                    setViewOption("list");
                  }}
                >
                  <MdViewList size={"20px"} />
                </div>
              </div>
            </div>
          </div>
          {viewOption === "grid" && (
            <GridRender
              currentItems={currentItems}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleRenameProject={handleRenameProject}
              handleDeleteProject={handleDeleteProject}
              open={open}
              OpenMenu={OpenMenu}
              selectedProject={selectedProject}
            />
          )}

          {viewOption === "list" && (
            <ListRender
              currentItems={currentItems}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleRenameProject={handleRenameProject}
              handleDeleteProject={handleDeleteProject}
              open={open}
              OpenMenu={OpenMenu}
              selectedProject={selectedProject}
              onSuccess={() => {}}
            />
          )}

          <div className="fixed bottom-0 left-0 right-0 flex justify-center py-3 bg-white">
            <PaginationButtons
              allProjects={allProjects}
              renderProject={renderProject}
              onPageChange={handlePageChange}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              option={viewOption}
              onSearch={onSearch}
            />
          </div>
          <RenameProject
            selectedProject={selectedProject}
            onRenameSuccessCallback={() => {
              if (localStorage.getItem("token")) {
                listProjectsByUserId();
              } else {
                listProjectsByUid();
              }
            }}
            setAnchorEl={setAnchorEl}
            existProjectNames={
              allProjectsName &&
              allProjectsName.map((prj) => {
                return prj;
              })
            }
          />
        </div>
      </div>
    </div>
  );
}
export default MyProjects;
