import "./App.css";
import {
  Route,
  Routes,
} from "react-router-dom";

import Project from "./pages/Project";
import MyProjects from "./pages/MyProjects";
import PageHeader from "./components/PageHeader";
import Login from "./Auth/Form/Login"

function App() {
  return (
    <div className="h-screen w-screen">
      <PageHeader/>
      <div className="h-[calc(100vh-60px)] w-full">
        <Routes>
          <Route path="/" element={<MyProjects />} />
          <Route path="project/:id" element={<Project />} />
        </Routes>
      </div>
      </div>
  );
}

export default App;
