import axios from "axios";
import { STATIC_HOST, STORE_BE } from "./config";

const axiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
  },
  params: {
    excludeRoot: false,
  },
};

const convertAxiosConfig = {
  headers: {
    "Cache-Control": "no-cache",
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
  params: {
    excludeRoot: false,
  },
};

if (localStorage.getItem("token")) {
  console.log("has token1");
  axiosConfig.headers.Authorization = "Bearer " + localStorage.getItem("token");
}

export const getProjectByUid = async (uid) => {
  try {
    let response = await axios.get(`${STATIC_HOST}project/all/${uid}`, {
      headers: {
        "Cache-Control": "no-cache",
      },
    });
    return response.data;
  } catch (error) {
    console.log("error while getting project by uid", error);
  }
};

export const getProjectByUserId = async () => {
  try {
    const response = await axios.get(
      `${STATIC_HOST}project/current`,
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.log("Error while get user's project", error);
  }
};

export const createNewProject = async (newProjectName, uid, visibility) => {
  try {
    const response = await axios.post(
      `${STATIC_HOST}project`,
      {
        name: newProjectName,
        uid: uid,
        visibility: visibility,
      },
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.log("error creating project", error);
  }
};

export const getProjectData = async (id) => {
  try {
    const res = await axios.get(`${STATIC_HOST}project/${id}`, axiosConfig);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log("error fetching project data", err);
  }
};

export const renameProject = async (projectCode, newProjectName, node) => {
  try {
    const res = await axios.put(
      `${STATIC_HOST}project/${projectCode}`,
      {
        name: newProjectName,
      },
      axiosConfig
    );
    if (res) {
      return res;
    }
  } catch (error) {
    console.error("Rename project failed", error);
  }
};

export const deployProject = async (
  projectCode,
  packageInfo,
  uploaded_file
) => {
  try {
    const res = await axios.post(
      `${STATIC_HOST}deploy/${projectCode}`,
      {
        name: packageInfo.packageName,
        category: packageInfo.category,
        thumbnail: uploaded_file?.url
          ? uploaded_file.url
          : "https://cdn-icons-png.flaticon.com/512/679/679821.png",
        visibility: packageInfo.visibility,
        entryPoint: packageInfo.entryFile,
        options: packageInfo.options,
      },
      axiosConfig
    );
    return res.data;
  } catch (error) {
    console.error("Error deploy to store:", error);
  }
};

export const getPackageId = async (projectCode) => {
  try {
    let res = await axios.get(`${STATIC_HOST}project/${projectCode}`);
    return res.data;
  } catch (error) {
    console.log("error get package ID", error);
  }
};

export const getPackageVersion = async (deployPackageId) => {
  if (deployPackageId) {
    try {
      let res = await axios.get(
        `${STORE_BE}package/${deployPackageId}/version`,
        axiosConfig
      );
      return res.data;
    } catch (error) {
      console.log("Error while getting package version", error);
    }
  }
};

export const updatePackageVersion = async (projectCode, version, desc) => {
  try {
    let response = await axios.post(
      `${STATIC_HOST}deploy/${projectCode}/publish-version`,
      {
        name: version,
        desc: desc,
      },
      axiosConfig
    );
    return response.data;
  } catch (error) {
    console.log("Error while updating package version", error);
  }
};

export const convertUidProject = async (uid, token) => {
  try {
    let response = await axios.post(`${STATIC_HOST}project/convert/${uid}`, {}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log("Error while convert uid's project", error);
  }
};


// export const convertUidProject = async (uid, token) => {
//   console.log("token", token);
//   try {
//     let response = await axios.post(`${STATIC_HOST}project/convert/${uid}`, {}, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.log("Error while convert uid's project", error);
//   }
// };