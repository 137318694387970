import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useState } from "react";
import { useViewport } from "../utils/useViewport";

export default function PaginationButtons({
  onPageChange,
  currentPage,
  setCurrentPage,
  option,
  renderProject,
  allProjects,
  onSearch,
}) {
  const [currentItems, setCurrentItems] = useState([]);
  const { width } = useViewport();
  const [calculateItem, setCalculateItem] = useState(0);

  let itemsPerPage;

  if (option === "grid") {
    // responsive();
    itemsPerPage = 18;
  } else if (option === "list") {
    itemsPerPage = 8;
  }

  const pageCount = Math.ceil(
    renderProject.length > 0
      ? renderProject.length / itemsPerPage
      : allProjects.length / itemsPerPage
  );

  const itemOffset = currentPage * itemsPerPage;

  useEffect(() => {
    if (onSearch) {
      if (renderProject.length > 0) {
        const newCurrentItems = renderProject.slice(
          itemOffset,
          itemOffset + itemsPerPage
        );
        setCurrentItems(newCurrentItems);
        // Call the callback function to pass the currentItems to the parent
        onPageChange(newCurrentItems);
      } else {
        setCurrentItems([]);
        onPageChange([]);
      }
    } else {
      const newCurrentItems = allProjects.slice(
        itemOffset,
        itemOffset + itemsPerPage
      );
      setCurrentItems(newCurrentItems);
      // Call the callback function to pass the currentItems to the parent
      onPageChange(newCurrentItems);
    }
  }, [currentPage, renderProject, option, allProjects]);

  const handlePageClick = (value) => {
    setCurrentPage(value - 1);
  };

  return (
    <div>
      {pageCount > 1 && (
        <Stack spacing={2}>
          <Pagination
            count={pageCount}
            shape="rounded"
            // onChange={handlePageClick}
            onChange={(event, value) => {
              handlePageClick(value);
            }}
            page={currentPage + 1}
            showFirstButton
            showLastButton
          />
        </Stack>
      )}
    </div>
  );
}
