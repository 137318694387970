import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import PasswordField from "../form-controls/PasswordField";
import InputField from "../form-controls/InputField";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { toast } from "react-toastify";
import { login, register } from "../../api/userAPI";

Register.propTypes = {};

function Register({ onCloseRegForm, isOpenRegForm, onOpenLoginForm }) {
  const schema = yup
    .object({
      fullName: yup.string().required("Please enter your name."),
      email: yup
        .string()
        .required("Please enter an email")
        .email("Please enter a valid email address"),
      password: yup
        .string()
        .required("Please enter a password")
        .min(8, "Please enter at least 8 characters")
        .test(
          "isValidPass",
          "Password needs an uppercase, lowercase, and a number.",
          (value, context) => {
            const hasUpperCase = /[A-Z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            let validConditions = 0;
            const numberOfMustBeValidConditions = 2;
            const conditions = [hasUpperCase, hasLowerCase, hasNumber];
            conditions.forEach((condition) =>
              condition ? validConditions++ : null
            );
            if (validConditions >= numberOfMustBeValidConditions) {
              return true;
            }
            return false;
          }
        ),
      retypePassword: yup
        .string()
        .required("Please retype password")
        .oneOf([yup.ref("password")], "Password does not match"),
    })
    .required();
  const form = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      retypePassword: "",
    },
    resolver: yupResolver(schema),
  });

  const handleSubmit = async (values) => {
    let user = await register(values);
    if (typeof user === "object" && user !== null) {
      let response = await login(user);
      if (response) {
        localStorage.setItem("token", response.token);
        onCloseRegForm();
        window.location.reload();
      } else {
        toast.error("Fail to register");
      }
    } else {
      toast.error(user);
    }
  };

  const { isSubmitting, isDirty } = form.formState;

  const [onAgree, setOnAgree] = useState(false);

  const onAgreeTerm = (event) => {
    if (event.target.checked) {
      setOnAgree(true);
    } else {
      setOnAgree(false);
    }
  };

  return (
    <div>
      <Dialog
        open={isOpenRegForm}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title" className="flex justify-between">
          <p className="text-xl font-bold cursor-pointer select-none bg-gradient-to-r from-[#005072] to-[#AEBD38] bg-clip-text text-transparent">
            SIGN UP
          </p>
          <button
            onClick={() => {
              onCloseRegForm();
              form.reset();
            }}
            type="button"
            className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
          >
            <span className="sr-only">Close</span>
            <svg
              className="w-3.5 h-3.5"
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </DialogTitle>
        <DialogContent>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="flex flex-col"
          >
            <InputField name="fullName" label="Fullname" form={form} />
            <InputField name="email" label="Email" form={form} />
            <PasswordField name="password" label="Password" form={form} />
            <PasswordField
              name="retypePassword"
              label="Retype Password"
              form={form}
            />
            <div className="mt-3">
              <div className="flex justify-start items-center">
                <input
                  type="checkbox"
                  name="terms"
                  className="mr-2 w-4 h-4 accent-[#005072]"
                  id="terms"
                  form={form}
                  onChange={onAgreeTerm}
                />
                <label
                  htmlFor="terms"
                  className="text-[#005072] font-medium cursor-pointer"
                >
                  I agree to the Terms and Conditions
                </label>
              </div>
              <button
                type="submit"
                className={`mt-3 py-4 text-white shadow-md rounded w-full bg-[#005072] ${
                  isDirty === false || !onAgree ? "opacity-50" : "opacity-100"
                }`}
                disabled={isDirty === false || !onAgree}
              >
                SIGN UP
              </button>
            </div>
          </form>
          <div className="mt-3 select-none">
            Already have account?{" "}
            <span
              className="font-bold cursor-pointer hover:underline text-[#005072]"
              onClick={() => {
                onCloseRegForm();
                onOpenLoginForm();
                form.reset();
              }}
            >
              Login
            </span>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Register;
