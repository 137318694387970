import axios from "axios";
import { toast } from "react-toastify";
import { getRootChildren } from "../TreeAPI/common";
import { useEffect, useState } from "react";
import useCheckUser from "../../utils/useCheckUser";
import { STATIC_HOST } from "../config";
import { getChildren } from "../folderAPI";

export const dropFunc = async (
  node,
  acceptedFiles,
  projectCode,
  setLoading,
  openOverwriteModal
) => {
  let overwriteFile = [];

  const isLoggedIn = useCheckUser();

  const axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      excludeRoot: false,
    },
  };

  if (isLoggedIn) {
    axiosConfig.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
  }

  const checkScope = (node) => {
    //check selected isDir or isFile
    if (!node?.isDir) {
      return node?.path.substr(
        node.path.indexOf("/"),
        node.path.lastIndexOf("/")
      );
    } else if (node?.isDir) {
      return node?.path;
    }
  };

  const checkDuplicateFile = async (files, node, projectCode) => {
    if (node === null || node.path === "/") {
      let allProjectChildren = await getRootChildren(projectCode);
      // let duplicateFile
      files.map((file) => {
        allProjectChildren.map((x) => {
          if (file.name.toLowerCase() === x.name.toLowerCase()) {
            overwriteFile.push(file);
          }
        });
      });

      return overwriteFile;
    } else {
      const allFiles = await getChildren(node.path, projectCode);
      files?.map((file) => {
        allFiles.map((x) => {
          if (file.name.toLowerCase() === x.name.toLowerCase()) {
            overwriteFile.push(file);
          }
        });
      });
      return overwriteFile;
    }
  };

  if (projectCode) {
    setLoading(true);
    // Upload to project
    if (node === null || node.path === "/") {
      const formData = new FormData();
      // UPLOAD A FILE
      if (acceptedFiles?.length === 1) {
        let duplicatedFile = await checkDuplicateFile(
          acceptedFiles,
          node,
          projectCode
        );
        if (duplicatedFile.length > 0) {
          openOverwriteModal(duplicatedFile);
        } else {
          acceptedFiles.forEach((file) => {
            formData.append("path", "/" + `${file.name}`);
            formData.append(
              "type",
              file.name.substr(file.name.lastIndexOf(".") + 1)
            );
            formData.append("project", projectCode);
            formData.append("uploaded_file", file);
          });
          await axios
            .post(
              `${STATIC_HOST}project/${projectCode}/upload-file`,
              formData,
              axiosConfig
            )
            .then((res) => {
              setLoading(false);
              toast.success("File created successfully");
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      }
      // // UPLOAD FILES
      else {
        let duplicatedFile = await checkDuplicateFile(
          acceptedFiles,
          node,
          projectCode
        );
        let notExsitedFileArray = acceptedFiles.filter(
          ({ name }) => !duplicatedFile.some((e) => e.name === name)
        );
        openOverwriteModal(duplicatedFile);
        formData.append("containPath", "/");
        notExsitedFileArray.forEach((file) => {
          formData.append(
            "type",
            file.name.substr(file.name.lastIndexOf(".") + 1)
          );
          formData.append("uploaded_files", file);
        });
        await axios
          .post(
            `${STATIC_HOST}project/${projectCode}/upload-file-bulk`,
            formData,
            axiosConfig
          )
          .then((res) => {
            setLoading(false);
            toast.success("File created successfully");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
    // Upload to folder
    else {
      const formData = new FormData();
      //UPLOAD A FILE
      if (acceptedFiles?.length === 1) {
        let duplicatedFile = await checkDuplicateFile(
          acceptedFiles,
          node,
          projectCode
        );
        if (duplicatedFile.length > 0) {
          openOverwriteModal(duplicatedFile);
        } else {
          acceptedFiles.forEach((file) => {
            formData.append(
              "type",
              file.name.substr(file.name.lastIndexOf(".") + 1)
            );
            formData.append("project", projectCode);
            formData.append("path", checkScope(node) + `/${file.name}`);
            formData.append("uploaded_file", file);
          });

          await axios
            .post(
              `${STATIC_HOST}project/${projectCode}/upload-file`,
              formData,
              axiosConfig
            )
            .then((res) => {
              setLoading(false);
              toast.success("File created successfully");
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
            });
        }
      }
      //UPLOAD FILES
      else {
        let duplicatedFile = [];
        let notExsitedFileArray = [];
        duplicatedFile = await checkDuplicateFile(
          acceptedFiles,
          node,
          projectCode
        );
        notExsitedFileArray = acceptedFiles?.filter(
          ({ name }) => !duplicatedFile.some((e) => e.name === name)
        );

        openOverwriteModal(duplicatedFile);
        formData.append("containPath", checkScope(node));
        acceptedFiles.forEach((file) => {
          formData.append(
            "type",
            file.name.substr(file.name.lastIndexOf(".") + 1)
          );
          formData.append("uploaded_files", file);
        });

        await axios
          .post(
            `${STATIC_HOST}project/${projectCode}/upload-file-bulk`,
            formData,
            axiosConfig
          )
          .then((res) => {
            setLoading(false);
            toast.success("File created successfully");
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      }
    }
    setLoading(false);
  }
};
