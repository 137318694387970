import useProjectCode from "../utils/useProjectCode";
import { getProjectChildren } from "../api/TreeAPI/common";
import { getLogo } from "../utils/getLogo";
import { FiMoreHorizontal } from "react-icons/fi";
import { VscFolderOpened, VscFolder } from "react-icons/vsc";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ContextMenu from "./Menu/ContextMenu";
import FileActionModal from "./Modal/File/FileActionModal";
import CreateFolderModal from "./Modal/Folder/CreateFolderModal";
import DeleteConfirmModal from "./Modal/DeleteConfirmModal";
import { useDispatch, useSelector } from "react-redux";
import { setActiveNode } from "../store/activeNodeSlice";
import { requestLogin } from "../store/requestLoginStatus";
import Tooltip from "@mui/material/Tooltip";
import { useSearchParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import LoadingDialog from "./Modal/LoadingDialog";
import { dropFunc } from "../api/UploadAPI/dropFunc";
import ListDuplicatedModal from "./Modal/File/ListDuplicatedModal";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import DeployModal from "./Modal/DeployModal";
import VersionUpdate from "./Modal/versionUpdate";
import { getPackageId } from "../api/projectAPI";
import { getChildren } from "../api/folderAPI";
import { getPath } from "../utils/common";
import { toast } from "react-toastify";
const CustomTreeItem = ({
  isRoot,
  node,
  triggerReload,
  openFileModal,
  requestReloadChildren,
}) => {
  const projectCode = useProjectCode();
  const [anchorEl, setAnchorEl] = useState(null); //Open/Close modal
  const activeNode = useSelector((state) => state.activeNode);
  const requestLoginStatus = useSelector((state) => state.requestLoginStatus);
  const [isFileActionModalOpen, setIsFileActionModalOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [folderMode, setFolderMode] = useState("");
  const [oldFilename, setOldFilename] = useState("");
  const [loading, setLoading] = useState(false);

  const [isDeploySuccess, setIsDeploySuccess] = useState(false);

  const [deployPackageId, setDeployPackageId] = useState("");

  useEffect(() => {
    const checkPackageId = async () => {
      let res = await getPackageId(projectCode);
      if (res) {
        setDeployPackageId(res.deployPackageId);
      }
    };
    checkPackageId();
  }, [projectCode, isDeploySuccess]);

  useEffect(() => {
    if (openFileModal) {
      openFileActionModal();
    } else {
      closeFileActionModal();
    }
  }, [openFileModal]);

  const openFileActionModal = (mode, node) => {
    setIsFileActionModalOpen(true);
    setMode(mode);
    setOldFilename(node?.filename);
  };
  const closeFileActionModal = () => {
    setIsFileActionModalOpen(false);
    onCloseMenu();
  };

  const [isOpenFolderModal, setIsOpenFolderModal] = useState(false);
  const openFolderModal = (mode, node) => {
    setIsOpenFolderModal(true);
    setFolderMode(mode);
  };
  const closeFolderModal = () => {
    setIsOpenFolderModal(false);
    onCloseMenu();
  };
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const openConfirmModal = () => {
    setIsOpenConfirmModal(true);
  };
  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false);
    onCloseMenu();
  };

  const [isOpenDeployModal, setIsOpenDeployModal] = useState(false);
  const openDeployModal = () => {
    //check if loggin
    if (localStorage.getItem("token")) {
      setIsOpenDeployModal(true);
      onCloseMenu();
    } else {
      toast.warning("Please login to deploy this project");
      dispatch(requestLogin());
      onCloseMenu();
    }
  };

  const closeDeployModal = () => {
    setIsOpenDeployModal(false);
    onCloseMenu();
  };

  const [isOpenVersionModal, setIsOpenVersionModal] = useState(false);
  const openVersionModal = () => {
    setIsOpenVersionModal(true);
    onCloseMenu();
  };
  const closeVersionModal = () => {
    setIsOpenVersionModal(false);
    onCloseMenu();
  };

  const [duplicatedFiles, setDuplicatedFiles] = useState([]);
  const [isOpenOverwriteModal, setIsOpenOverwriteModal] = useState(false);
  const [overWriteFiles, setOverWriteFiles] = useState([]);

  const getOverWriteFiles = (files) => {
    setOverWriteFiles(files);
  };

  const openOverwriteModal = (duplicatedFiles) => {
    setDuplicatedFiles([...duplicatedFiles]);
    setIsOpenOverwriteModal(true);
  };

  const closeOverwriteModal = () => {
    setIsOpenOverwriteModal(false);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [children, setChildren] = useState();
  const [collapsed, setCollapsed] = useState(true);
  const [loadingChildren, setLoadingChildren] = useState(false);

  useEffect(() => {
    loadRootChildren();
  }, [projectCode]);

  useEffect(() => {
    if (triggerReload) {
      loadRootChildren();
    }
  }, [triggerReload]);

  const loadRootChildren = async () => {
    if (isRoot) {
      setLoadingChildren(true);
      setChildren([]);
      await getProjectChildren(projectCode, setChildren);
      setLoadingChildren(false);
      setCollapsed(false);
    }
  };

  const loadDirChildren = async () => {
    if (node?.isDir) {
      setCollapsed(false);
      setLoadingChildren(true);
      setChildren([]);
      let fetchedChildren = await getChildren(node.path, projectCode);
      setChildren(fetchedChildren);
      setLoadingChildren(false);
    }
  };

  const onNodeClicked = async () => {
    if (!node.isDir) {
      navigate(
        `/project/${projectCode}?fileName=${encodeURIComponent(node.path)}`
      );
    }
    if (node.path === "/") {
      if (collapsed) {
        loadRootChildren();
      } else {
        setCollapsed(true);
      }
    } else {
      if (collapsed) {
        if (node.isDir) {
          loadDirChildren();
        }
      } else {
        setCollapsed(true);
      }
    }
    //dispatch after the async request ends
    dispatch(setActiveNode(node));
  };

  const handleOpenMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onCloseMenu = () => {
    setAnchorEl(null);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const onActionSuccess = async (node, finalFilename) => {
    onCloseMenu();
    if (!node) return;
    if (requestReloadChildren) {
      requestReloadChildren();

      //Replace url when rename active node
      if (node === activeNode && !node.isDir) {
        setSearchParams({
          fileName: node.path.replace(node.name, finalFilename),
        });
      }
      return;
    }
    if (node.path === "/") {
      loadRootChildren();
      return;
    }
    setLoadingChildren(true);
    setChildren([]);

    try {
      const fetchedChildren = await getChildren(
        node.isDir ? node.path : getPath(node),
        projectCode
      );
      setChildren(fetchedChildren);
    } catch (error) {
      console.error("Error fetching children:", error);
    } finally {
      setLoadingChildren(false);
    }
  };

  const onActionFolderSuccess = async (node, newProject) => {
    onCloseMenu();
    if (!node) return;
    if (folderMode === "rename") {
      if (node.path === "/") {
        let newNode = { path: "/", name: newProject, isDir: true };
        let keys = Object.keys(newNode);
        keys.map((x) => {
          node[x] = newNode[x];
        });
      }
      if (requestReloadChildren) {
        requestReloadChildren();
      }
      return;
    }
    if (node.path === "/") {
      loadRootChildren();
      return;
    }
    setLoadingChildren(true);
    setChildren([]);
    let fetchedChildren = await getChildren(node.path, projectCode);
    setChildren(fetchedChildren);
    setLoadingChildren(false);
  };

  const onDeleteSuccess = async () => {
    onCloseMenu();
    if (requestReloadChildren) {
      requestReloadChildren();
    }
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setTimeout(function () {
        if (node === null || node?.path === "/") {
          loadRootChildren();
        } else {
          loadDirChildren();
        }
      }, 3000);
      await dropFunc(
        node,
        acceptedFiles,
        projectCode,
        setLoading,
        openOverwriteModal,
        overWriteFiles
      );
    },
    [overWriteFiles]
  );

  const noClick = true;
  const noKeyboard = true;
  const noDragEventsBubbling = true;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick,
    noKeyboard,
    noDragEventsBubbling,
  });

  const { isDragActive: isDragActiveInside } = useDropzone({
    onDragEnter: () => setHovered(true),
    onDragLeave: () => setHovered(false),
  });

  const isDragActiveOutside = isDragActive || isDragActiveInside;

  return (
    <div
      className="w-full grow relative overflow-y"
      {...(node?.isDir
        ? {
            ...getRootProps({
              className: `${
                isDragActiveOutside
                  ? "bg-[#80808038] border-2 border-dashed border-[#9CA3AF]"
                  : ""
              }`,
            }),
          }
        : {})}
    >
      {node && (
        <div
          className={`flex items-center text-[12px] py-0.5 hover:bg-slate-200 cursor-pointer ${
            node.path === "/" ? "font-bold uppercase bg-[#efefef] " : ""
          } 
          ${activeNode === node ? "font-bold bg-slate-200" : ""} `}
          onClick={onNodeClicked}
          onContextMenu={(e) => {
            e.preventDefault();
            handleOpenMenu(e);
          }}
        >
          <div className="max-w-[20px] min-w-[20px] flex justify-center">
            {node?.isDir ? (
              collapsed ? (
                <VscFolder />
              ) : (
                <VscFolderOpened />
              )
            ) : (
              getLogo(node)
            )}
          </div>
          <Tooltip title={node.name} arrow placement="top" enterDelay={500}>
            <div className="grow px-1 truncate">{node.name}</div>
          </Tooltip>
          {node?.path === "/" && deployPackageId && (
            <Tooltip
              title="Already deploy to store"
              arrow
              placement="top"
              enterDelay={500}
            >
              <HiOutlineBuildingStorefront size="1rem" color="rgb(37 99 235)" />
            </Tooltip>
          )}

          <div
            className="max-w-[20px] min-w-[20px] flex justify-center"
            onClick={(e) => handleOpenMenu(e)}
          >
            <FiMoreHorizontal />
          </div>
        </div>
      )}
      {!collapsed && (
        <>
          {loadingChildren && (
            <div className="pl-10 text-[11px] text-slate-500">Loading...</div>
          )}
          {children && (
            <div className="ml-2 pl-2 border-l border-slate-200">
              {children.map((child, cIndex) => (
                <CustomTreeItem
                  key={cIndex}
                  node={child}
                  isRoot={false}
                  requestReloadChildren={() => {
                    loadRootChildren();
                    loadDirChildren();
                  }}
                />
              ))}
            </div>
          )}
          {isRoot && !children && !loadingChildren && (
            <div className="text-gray-400 py-5 px-2 text-center bg-slate-100 mt-2">
              <i>There is no files</i>
            </div>
          )}
        </>
      )}

      {loading && <LoadingDialog />}

      <ContextMenu
        anchorEl={anchorEl}
        handleClose={onCloseMenu}
        node={node}
        openFileActionModal={openFileActionModal}
        openFolderModal={openFolderModal}
        openConfirmModal={openConfirmModal}
        openDeployModal={openDeployModal}
        openVersionModal={openVersionModal}
        children={children}
        isDeploySuccess={isDeploySuccess}
      />
      <FileActionModal
        isFileActionModalOpen={isFileActionModalOpen}
        handleClose={closeFileActionModal}
        mode={mode}
        onActionSuccess={(node, finalFilename) => {
          onActionSuccess(node, finalFilename);
        }}
        oldFilename={oldFilename}
        node={node}
        existFileNames={children ? children.map((file) => file.name) : []}
      />
      <CreateFolderModal
        isOpenFolderModal={isOpenFolderModal}
        handleClose={closeFolderModal}
        existFolderNames={children ? children.map((file) => file.name) : []}
        node={node}
        mode={folderMode}
        onActionFolderSuccess={(newProject) => {
          onActionFolderSuccess(node, newProject);
        }}
      />
      <DeleteConfirmModal
        isOpenConfirmModal={isOpenConfirmModal}
        handleClose={closeConfirmModal}
        node={node}
        onCloseMenu={onCloseMenu}
        onActionSuccess={() => {
          onDeleteSuccess(node);
        }}
      />
      <ListDuplicatedModal
        getOverWriteFiles={getOverWriteFiles}
        node={node}
        isOpenOverwriteModal={isOpenOverwriteModal}
        handleClose={closeOverwriteModal}
        duplicatedFiles={duplicatedFiles}
      />
      <DeployModal
        isOpenDeployModal={isOpenDeployModal}
        handleClose={closeDeployModal}
        node={node}
        isDeploySuccess={() => setIsDeploySuccess(true)}
      />
      <VersionUpdate
        isOpenVersionModal={isOpenVersionModal}
        handleClose={closeVersionModal}
        deployPackageId={deployPackageId}
      />
    </div>
  );
};

export default CustomTreeItem;
