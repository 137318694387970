import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDropzone } from "react-dropzone";
import { STATIC_HOST } from "../../api/config";
import useProjectCode from "../../utils/useProjectCode";
import useUserId from "../../utils/useUserId";

import { useSelector } from "react-redux";
import useCheckUser from "../../utils/useCheckUser";

const WholePageDropZone = ({ onAddSuccess }) => {
  const [mounted, setMounted] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  const isLoggedIn = useCheckUser();
  const axiosConfig = {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
    params: {
      excludeRoot: false,
    },
  };

  if (isLoggedIn) {
    axiosConfig.headers.Authorization =
      "Bearer " + localStorage.getItem("token");
  }

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  const projectCode = useProjectCode();
  const userId = useUserId();

  const activeNode = useSelector((state) => state.activeNode);
  let totalFiles = localStorage.getItem(`${projectCode}_files`);
  let allFiles = JSON.parse(totalFiles);

  const handleDrop = async (acceptedFiles) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("containPath", "/");
    acceptedFiles.forEach((file) => {
      formData.append("type", file.name.substr(file.name.lastIndexOf(".") + 1));
      formData.append("uploaded_files", file);
    });

    axios
      .post(
        `${STATIC_HOST}project/${projectCode}/upload-file-bulk`,
        formData,
        axiosConfig
      )
      .then((res) => {
        setLoading(false);
        toast.success("File uploaded successfully");
        onAddSuccess();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
  });

  const { isDragActive: isDragActiveInside } = useDropzone({
    onDragEnter: () => setHovered(true),
    onDragLeave: () => setHovered(false),
  });

  const isDragActiveOutside = isDragActive || isDragActiveInside;

  return (
    <div
      {...getRootProps()}
      style={{
        height: "100%",
        width: "100%",
        border: "2px dashed rgb(156 163 175)",
        backgroundColor: "#80808038",
        left: "48px",
        top: "5%",
        opacity: `${isDragActiveOutside ? "1" : "0"}`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <input {...getInputProps()} />
    </div>
  );
};

export default WholePageDropZone;
