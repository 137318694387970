import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import axios from "axios";
import { STATIC_HOST } from "../../../api/config";
import useProjectCode from "../../../utils/useProjectCode";
import { toast } from "react-toastify";
import { uploadExistFiles } from "../../../api/fileAPI";

export default function ListDuplicatedModal({
  isOpenOverwriteModal,
  handleClose,
  duplicatedFiles,
  getOverWriteFiles,
  node,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [onEnter, setOnEnter] = useState(false);

  const projectCode = useProjectCode();

  const [checkedState, setCheckedState] = useState([]);

  useEffect(() => {
    if (duplicatedFiles) {
      setCheckedState(new Array(duplicatedFiles.length).fill(true));
    }
  }, [duplicatedFiles]);

  const handleCheckOverwrite = (position) => {
    let updatedCheckedState;

    updatedCheckedState = [...checkedState];
    updatedCheckedState[position] = !updatedCheckedState[position];

    setCheckedState(updatedCheckedState);
  };

  const checkScope = (node) => {
    //check selected isDir or isFile
    if (!node?.isDir) {
      return node?.path.substr(
        node.path.indexOf("/"),
        node.path.lastIndexOf("/")
      );
    } else if (node?.isDir) {
      return node?.path;
    }
  };

  const handleOverwriteFile = async (e) => {
    //replace file
    let temp = [];
    for (let i = 0; i < checkedState.length; i++) {
      if (checkedState[i] === true) {
        temp.push(duplicatedFiles[i]);
        getOverWriteFiles(temp);
      }
    }
    const formData = new FormData();
    if (node.path === "/" || node.path === null) {
      formData.append("containPath", "/");
    } else {
      formData.append("containPath", checkScope(node));
    }
    if (temp) {
      temp.forEach((file) => {
        formData.append(
          "type",
          file.name.substr(file.name.lastIndexOf(".") + 1)
        );
        formData.append("uploaded_files", file);
      });
    }
    let res = await uploadExistFiles(projectCode, formData);
    if (res) {
      handleClose();
      toast.success(`Overwrite ${temp.length} file successfully`);
      window.location.reload();
    } else {
      toast.error(`Fail to overwrite`);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpenOverwriteModal}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="flex flex-col">
          <div className="font-bold text-gray-800 flex justify-between items-center">
            Existed files
            <button
              onClick={handleClose}
              type="button"
              className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-gray-500 hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-sm dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-3.5 h-3.5"
                width="8"
                height="8"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.258206 1.00652C0.351976 0.912791 0.479126 0.860131 0.611706 0.860131C0.744296 0.860131 0.871447 0.912791 0.965207 1.00652L3.61171 3.65302L6.25822 1.00652C6.30432 0.958771 6.35952 0.920671 6.42052 0.894471C6.48152 0.868271 6.54712 0.854471 6.61352 0.853901C6.67992 0.853321 6.74572 0.865971 6.80722 0.891111C6.86862 0.916251 6.92442 0.953381 6.97142 1.00032C7.01832 1.04727 7.05552 1.1031 7.08062 1.16454C7.10572 1.22599 7.11842 1.29183 7.11782 1.35822C7.11722 1.42461 7.10342 1.49022 7.07722 1.55122C7.05102 1.61222 7.01292 1.6674 6.96522 1.71352L4.31871 4.36002L6.96522 7.00648C7.05632 7.10078 7.10672 7.22708 7.10552 7.35818C7.10442 7.48928 7.05182 7.61468 6.95912 7.70738C6.86642 7.80018 6.74102 7.85268 6.60992 7.85388C6.47882 7.85498 6.35252 7.80458 6.25822 7.71348L3.61171 5.06702L0.965207 7.71348C0.870907 7.80458 0.744606 7.85498 0.613506 7.85388C0.482406 7.85268 0.357007 7.80018 0.264297 7.70738C0.171597 7.61468 0.119017 7.48928 0.117877 7.35818C0.116737 7.22708 0.167126 7.10078 0.258206 7.00648L2.90471 4.36002L0.258206 1.71352C0.164476 1.61976 0.111816 1.4926 0.111816 1.36002C0.111816 1.22744 0.164476 1.10028 0.258206 1.00652Z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
          <p className="font-normal !text-sm py-2">
            These following files already exist. Please take an action to
            overwrite or skip the file.
          </p>
        </DialogTitle>
        <DialogContent className="!p-4 !border-b text-sm overflow-y-scroll">
          {duplicatedFiles.length === 0 && <div>No file to overwrite</div>}
          {duplicatedFiles.length !== 0 && (
            <table className="w-full text-left">
              <thead>
                <tr>
                  <th className="w-11 p-2 border border-[#dddddd]">No</th>
                  <th className="border p-2 border-[#dddddd]">FileName</th>
                  <th className="w-16 p-2 border border-[#dddddd] text-center">
                    Overwrite
                  </th>
                </tr>
              </thead>
              <tbody>
                {duplicatedFiles.map((arr, i) => {
                  return (
                    <tr key={i} className="odd:bg-gray-100 hover:bg-stone-100">
                      <td className="w-11 p-2 border border-[#dddddd]">
                        {i + 1}
                      </td>
                      <td className="border p-2 border-[#dddddd]">
                        {arr.name}
                      </td>
                      <td className="w-16 p-2 border border-[#dddddd] text-center">
                        <input
                          type="checkbox"
                          id={i}
                          name={arr.name}
                          value={arr.name}
                          className="cursor-pointer"
                          onChange={() => handleCheckOverwrite(i)}
                          checked={checkedState[i]}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: "12px 16px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: "white",
              color: "rgb(55 65 81)",
              padding: "12px 16px",
              border: "1px solid #80808033",
              "&:hover": {
                backgroundColor: "rgb(249 250 251)",
              },
              borderRadius: "0.375rem",
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleOverwriteFile}
            disabled={duplicatedFiles.length === 0}
            sx={{
              padding: "12px 16px",
              backgroundColor:
                onEnter === true ? "rgb(37 99 235)" : "rgb(59 130 246)",
              color: "white",
              "&:hover": {
                backgroundColor: "rgb(37 99 235)",
              },
              "&:disabled": {
                backgroundColor: "rgb(147 197 253) !important",
                color: "white !important",
              },
              borderRadius: "0.375rem",
            }}
          >
            Overwrite All
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
