import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useSelector } from "react-redux";
import axios from "axios";
import useProjectCode from "../utils/useProjectCode";
import { STATIC_HOST } from "../api/config";
import { useViewport } from "../utils/useViewport";

Preview.propTypes = {};

function Preview({
  contract,
  hidePreview,
  previewWidth,
  previewUrl,
  filePath,
}) {
  const [previewMarkdown, setPreviewMarkdown] = useState("");


  const projectCode = useProjectCode();

  const [previewItem, setPreviewItem] = useState("html");

  const IMAGE_FILE = ["png", "jpg", "jpeg", "gif", "svg", "webp"];
  const VIDEO_FILE = ["mp3", "mp4", "m4p", "m4v", "webm", "ogg", "mp2", "mpg", "mpv", "mov", "mpeg",];

  useEffect(() => {
    let type = filePath
      .substring(filePath.lastIndexOf(".") + 1)
      .toLowerCase();

    if (IMAGE_FILE.includes(type)) {
      setPreviewItem("image");
    }
    if (VIDEO_FILE.includes(type)) {
      setPreviewItem("video");
    }
    if (type === "md") {
      setPreviewItem("md");
    }
  }, [previewUrl]);

  const getMarkdownPreview = async () => {
    let res = await axios.get(`${STATIC_HOST}project/${projectCode}/file`, {
      params: { path: filePath },
    });
    return res.data.content;
  };

  useEffect(() => {
    if (filePath.substring(filePath.lastIndexOf(".") + 1).toLowerCase() === "md") {
      let mounted = true;
      getMarkdownPreview().then((res) => {
        if (mounted) {
          setPreviewMarkdown(res);
        }
      });
      return () => (mounted = false);
    }
  }, [previewUrl]);

  const { width } = useViewport();

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (width < 620) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <div
      className={`bg-slate-200 border-[1px] border-gray-300 h-full ${
        contract ? "w-full" : ""
      } `}
    >
      <div
        className={`bg-transparent h-full px-3 py-2 ${
          hidePreview ? "hidden" : ""
        } overflow-auto`}
        style={{ minWidth: isMobile ? "" : previewWidth }}
      >
        {previewItem === "md" ? (
          <ReactMarkdown
            className="prose lg:prose-xl"
            children={previewMarkdown}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
          />
        ) : (
          <div className="w-full h-full">
            {previewItem === "html" && (
              <iframe
                id="previewIframe"
                src={previewUrl}
                height="100%"
                width="100%"
                className="bg-white-100"
                allow="camera;microphone"
              ></iframe>
            )}
            {previewItem === "image" && (
              <img src={`${previewUrl}?date=${Date.now()}`} className="object-contain" />
            )}
            {previewItem === "video" && (
              <iframe
                src={previewUrl}
                className="w-full h-full"
                allow="camera;microphone"
              ></iframe>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Preview;
